<template>
  <section>
    <v-container fluid>
      <v-row>
        <v-col md="12">
          <PageHeader
            :title="headercontent.title"
            :addlink="headercontent.addlink"
          />
        </v-col>
        <v-col md="12">
          <v-data-table
              :items="allChallenges.results" 
              :headers="headers" 
              :server-items-length="allChallenges.pagination.total"
              :options.sync="options"
              :loading="loading"
          >
            <template v-slot:item.image="{ item }">
              <div class="imageBox">
                <img :src="item.image" alt="" width="100" height="100" />
              </div>
            </template>
            <template v-slot:item.status="{ item }">
              <v-switch
                v-model="item.status"
                @change="updateChallengeStatus(item._id, item.status)"
              ></v-switch>
            </template>
            <template v-slot:item.actions="{ item }">
              <router-link :to="`/dashboard/challenges/${item._id}`">
                <v-icon>mdi-pencil</v-icon>
              </router-link>
            </template>
          </v-data-table>
          <div v-if="loading">
            <v-skeleton-loader type="image" height="200"></v-skeleton-loader>
            <v-skeleton-loader type="image" height="200"></v-skeleton-loader>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
import PageHeader from "@/components/dashboard/PageHeader";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "ChallangeList",
  components: { PageHeader },
  data: () => ({
    headercontent: {
      title: "Challanges",
      addlink: "/dashboard/challenges/add",
    },
    loading: true,
    headers: [
      { text: 'Challenge Name', value: 'challengeName', sortable: false },
      { text: 'Image', value: 'image', sortable: false },
      { text: 'Status', value: 'status', sortable: false },
      { text: 'Actions', value: 'actions', sortable: false }
    ],
    options: {
      itemsPerPage: 5,
      page: 1
    },
  }),
  methods: {
    validate() {},
    ...mapActions(["fetchChallenges", "updateChallenge"]),
    async updateChallengeStatus(id, status) {
      await this.updateChallenge({
        id,
        data: { status },
      });
    },
    async reloadChallenges() {
      this.loading = true;
      await this.fetchChallenges({ limit: this.options.itemsPerPage, skip: (this.options.page - 1) * this.options.itemsPerPage });
      this.loading = false;  
    }
  },
  async created() {
    await this.reloadChallenges();
  },
  computed: {
    ...mapGetters(["allChallenges"]),
  },
  watch: {
    options: {
      async handler() {
        await this.reloadChallenges();
      },
      deep: true,
    }
  }
};
</script>

<style lang="scss" scoped>
.imageBox {
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  img {
    width: 100px;
    height: 80px;
    object-fit: contain;
    transition: 0.1s all ease-in-out;
    cursor: pointer;
    &:hover {
      position: absolute;
      width: 300px;
      height: 300px;
      object-fit: contain;
      transition: 0.1s all ease-in-out;
    }
  }
}
</style>
